<template>
  <FormInput
    :fieldValue.sync="keyword"
    :label="label"
    :placeholder="searchBarPlaceholder"
    isSearch
    dense
    hideDetails
    @search-click="clickSearch()"
    @input-value="inputSearch()"
    @clear-value="clearSearch()"
    customInputClass="mr-3 mt-2 mb-3 search-input-field"
    :isSearchId="searchType === 'id'"
    :type="searchType === 'order_id' ? 'number' : (searchType === 'phone' ? 'tel' : 'text')"
    :isIntegerOnly="searchType === 'order_id'"
    :prefix="searchType === 'order_id' ? 'DN-' : ''"
    :readonly="isSearching"
    :enabledClear="enabledClear"
  >
    <template slot="prepend-inner" v-if="enableMultiTypeSearch">
      <v-select
        :value="searchType"
        @change="searchTypeChange($event)"
        class="search-input-selector"
        background-color="transparent"
        :items="searchTypeOptions"
        solo
        flat
        height="24"
        dense
        hide-details
        :placeholder="$t('table.search')"
      ></v-select>
    </template>
  </FormInput>
</template>

<script>
import FormInput from './formField/FormInput.vue'
export default {
  name: 'Searchbar',
  components: {
    FormInput,
  },
  props: {
    isSearching: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: '',
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'table.search',
    },
    searchType: {
      type: String,
      default: '',
    },
    searchTypeOptions: {
      type: Array,
      default: () => [],
    },
    enableMultiTypeSearch: {
      type: Boolean,
      default: false,
    },
    enabledClear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    searchBarPlaceholder() {
      if (this.$validate.DataValid(this.searchType)) {
        if (this.$validate.DataValid(this.searchTypeOptions)) {
          const target = this.searchTypeOptions.find(el => el.value === this.searchType)
          if (target) {
            switch (this.$i18n.locale) {
              case 'en':
                return `Search ${target.text}`
              case 'cn':
                return `搜寻${target.text}`
              default:
                return `搜尋${target.text}`
            }
          }
        }
      }

      return this.searchPlaceholder
    },
  },
  data: () => ({
    keyword: '',
  }),
  watch: {
    searchText: {
      handler(val) {
        this.keyword = val
      },
      immediate: true,
    },
  },
  methods: {
    searchTypeChange(val) {
      this.$emit('update:searchType', val)
      this.$emit('search-type-changed', val)
    },
    clickSearch() {
      this.$emit('update:searchText', this.keyword)
      this.$emit('search-clicked', this.keyword)
    },
    inputSearch() {
      this.$emit('update:searchText', this.keyword)
      this.$emit('search-input', this.keyword)
    },
    clearSearch() {
      this.$emit('update:searchText', '')
      this.$emit('search-clear', '')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .search-input-selector {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  margin-bottom: 0 !important;

  & input {
    display: none !important;
  }

  & .v-input__icon {
    color: #d9d9d9;
    height: 10px !important;
    width: 10px !important;
    min-width: 10px !important;
  }

  & .v-input__control {
    min-height: 24px !important;
    // border: 1px solid #d9d9d9;
    border: none;
  }

  & .v-input__slot {
    padding: 0 5px !important;
  }

  & .v-label,
  & .v-select__selection {
    font-size: 12px;
    color: var(--v-colorGrey-base) !important;
    margin-right: 0 !important;
    width: 100%;
    // text-align: center;
  }

  & .v-input__append-inner {
    // display: none !important;
    margin-top: 0 !important;
  }
}
</style>
