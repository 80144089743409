<template>
  <div>
    <v-card class="custom-table" :class="{ 'show-select': enableSelectToggle }">
      <v-toolbar flat class="custom-table-toolbar" height="auto" :class="{ 'no-wrap': noWrapToolbar }">
        <v-toolbar-items class="toolbar-left-items flex-grow-1">
          <!-- Table Name -->
          <div v-if="$validate.DataValid(tableName)" class="custom-table-title">
            <span class="mr-4">{{ $t(tableName) }}</span>
            <small v-if="$validate.DataValid(tableSmallText)">{{ $t(tableSmallText) }}</small>
          </div>

          <!-- Search Bar -->
          <Searchbar
            v-if="enableSearch"
            :searchText.sync="searchText"
            :searchPlaceholder="searchPlaceholder"
            :searchType="searchType"
            :searchTypeOptions="searchTypeOptions"
            @search-clicked="$emit('search-clicked', $event)"
            @search-input="$emit('search-input', $event)"
            @search-type-changed="$emit('update:searchType', $event)"
            :enableMultiTypeSearch="enableMultiTypeSearch"
          />

          <!-- 進階搜尋 Button -->
          <v-btn
            v-if="enableAdvancedSearch"
            :disabled="disablePagination"
            class="mr-3 mt-2 mb-3"
            depressed
            color="primary"
            @click="clickAdvancedSearch()"
          >{{ $t('table.advanceSearch') }}</v-btn>

          <!-- Search Date Range -->
          <FormDatePicker
            v-if="enableDateRange"
            isDateRange
            :dateValue.sync="searchDateRange"
            dense
            hideDetails
            customInputClass="mr-3 mt-2 mb-3 search-input-field"
            :placeholder="dateRangePlaceholder"
            :disabled="disablePagination"
            @changed="selectDateRange()"
          />

          <!-- Search Single Date -->
          <FormDatePicker
            v-if="enableSearchDate"
            :dateValue.sync="searchDate"
            dense
            hideDetails
            customInputClass="mr-3 mt-2 mb-3 search-input-field"
            :placeholder="datePlaceholder"
            :disabled="disablePagination"
            @changed="selectDate()"
          />

          <slot name="append-toolbar-left"></slot>
        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-toolbar-items class="toolbar-right-items">
          <!-- Custom Button -->
          <slot name="custom-table-button"></slot>

          <!--------------- Default Button --------------->
          <!-- clear search -->
          <v-btn v-if="enableClearAll" text small :disabled="disablePagination" color="error" @click="clickClearAll()">
            <v-icon class="mr-1" color="error" size="18"> {{ icon.mdiClose }} </v-icon>
            {{ $t('table.clearSearch') }}
          </v-btn>

          <!-- refresh -->
          <v-btn v-if="enableRefresh" :disabled="disablePagination" text small color="primary" @click="clickRefresh()">
            <v-icon class="mr-1" color="primary" size="18"> {{ icon.mdiRefresh }} </v-icon>
            {{ $t('table.reloadTable') }}
          </v-btn>

          <!-- export data -->
          <v-btn
            v-if="enableExport"
            text
            small
            color="success"
            target="_blank"
            rel="noreferrer noopenner"
            @click="clickExport()"
          >
            <v-icon class="mr-1" color="success" size="18"> {{ icon.mdiFileTableOutline }} </v-icon>
            {{ $t('table.exportReport') }}
          </v-btn>
          <!-- import data -->
          <v-btn
            v-if="enableImport"
            text
            small
            color="success"
            target="_blank"
            rel="noreferrer noopenner"
            @click="_triggerUploadFile()"
          >
            <v-icon class="mr-1" color="success" size="18"> {{ icon.mdiTableArrowLeft }} </v-icon>
            {{ $t('table.importData') }}
          </v-btn>
          <v-file-input v-show="false" id="table-import-file" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="clickImport($event)"></v-file-input>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        class="custom-table"
        v-model="selectedRow"
        :locale="locale"
        :loading="processing"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="disablePagination ? -1 : pageLimit"
        :page="page"
        :server-items-length="disablePagination ? tableData.length : itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelectToggle"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader || hideDefaultHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="!enableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="!enableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: $vuetify.breakpoint.xsOnly ? false : !disableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: $t('table.itemPerPage'),
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: $t('table.all'),
          pageText: $t('table.displayItems', { first: ((page - 1) * pageLimit + 1), last: Math.min(page * pageLimit, itemTotal === -1 ? tableData.length : itemTotal)})
        }"
        :loading-text="$t('table.processing')"
        :no-data-text="$t(tableEmptyText)"
        :no-results-text="$t(tableEmptyText)"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
        @input="updateSelectedRow"
      >
        <!-- Header checkboxes with action -->
        <template v-slot:header.data-table-select="{ props, on }">
          <div class="d-flex align-center">
            <v-simple-checkbox
              v-on="on"
              v-ripple
              v-bind="props"
              @click="onCustomSelectClick($event, -1)"
              :disabled="disabledSelectAll"
              :class="{
                'pointer-none': $store.getters.isLoading || processing || disabledSelectAll,
                'checkbox-disabled': disabledSelectAll,
              }"
            ></v-simple-checkbox>
            <v-select
              v-if="enableSelectAction"
              v-model="selectAction"
              class="action-selector"
              background-color="transparent"
              :items="[
                { text: $t('table.edit'), value: 'edit' },
                { text: $t('delete'), value: 'delete' },
              ]"
              :label="$t('table.action')"
              solo
              flat
              height="24"
              color="white"
              dense
              hide-details
              @change="selectActionChanged($event)"
              :disabled="!$validate.DataValid(tableData)"
            >
              <template v-slot:append>
                <v-icon class="fi fi-rr-angle-small-down" size="18"></v-icon>
              </template>
            </v-select>
          </div>
        </template>

        <template v-slot:item.data-table-select="{ isSelected, on, attrs, item, select }">
          <div>
            <v-simple-checkbox
              v-on="on"
              v-bind="attrs"
              v-ripple
              :value="isSelected"
              @input="select($event)"
              @click="onCustomSelectClick($event, item.id)"
              :disabled="item.disabled"
              :readonly="item.readonly"
              :class="{
                'pointer-none': $store.getters.isLoading || item.disabled || item.readonly,
                'checkbox-readonly': item.readonly,
                'checkbox-disabled': item.disabled,
              }"
            ></v-simple-checkbox>
          </div>
        </template>

        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName" />
        </template>

        <!------------------- 繳交學費 ------------------->
        <template v-slot:[`item.student_id`]="{ item }">
          <span>{{ $formatter.generateId(item.student_id) }}</span>
        </template>

        <template v-slot:[`item.paymentAction`]="{ item }">
          <v-chip
            v-if="$validate.DataValid(item.order_id) && item.order_id > -1"
            :class="{
              'chip-active': item.order_status === 'expired',
              'chip-error': item.order_status === 'cancelled',
              'chip-inactive':
                item.order_status === 'pending' || item.order_status === 'null' || item.order_status === 'processing',
            }"
            >{{ item.order_status | formatOrderStatus }}</v-chip
          >
          <div class="d-flex align-center justify-end" v-else>
            <v-btn
              text
              icon
              min-width="max-content"
              class="pa-0 mx-2"
              :ripple="false"
              @click="clickActionEdit(item.id)"
              :class="{ 'pointer-none': $store.getters.isLoading }"
            >
              <v-icon class="fi fi-ss-pen-circle" color="accent" size="24"></v-icon>
            </v-btn>
            <v-btn
              text
              icon
              min-width="max-content"
              class="pa-0 mx-2"
              :ripple="false"
              @click="clickActionDelete(item.id)"
              :class="{ 'pointer-none': $store.getters.isLoading }"
            >
              <v-icon class="fi fi-ss-cross-circle" color="red" size="24"></v-icon>
            </v-btn>
          </div>
        </template>

        <!-- 報讀新課程 Search filter -->
        <template v-slot:[`item.current_per_max_student_num`]="{ item, header }">
          <span v-if="header.hasUnit">{{ $t('calendar.currentPerMaxStudent', { current: item.current_student_num || '0', max: item.max_student || '0' }) }}</span>
          <span v-else>{{ item.current_student_num || '0' }}/{{ item.max_student || '0' }}</span>
        </template>

        <!-- 新增產品 Search filter -->
        <template v-slot:[`item.qtySetter`]="{ item }">
          <QtySetter
            :qty.sync="item.qty"
            :max="item.inventory"
            @update-value="$emit('update:item.qty', $event)"
          ></QtySetter>
        </template>

        <!------------------- 近期帳單 ------------------->
        <template v-slot:[`item.order_id`]="{ item }">
          <span>{{ $formatter.generateOrderId(item.order_id) }}</span>
        </template>

        <template v-slot:[`item.order_type`]="{ item }">
          <span>{{ item.order_type | formatOrderType }}</span>
        </template>

        <!------------------- 學生管理 ------------------->
        <template v-slot:[`item.subject`]="{ item }">
          <span>{{ item.subject | formatSubject }}</span>
        </template>

        <!------------------- 商品管理 ------------------->
        <template v-slot:[`item.cost`]="{ item }">
          <span v-if="$validate.DataValid(item.cost)">HK${{ $formatter.separator(item.cost) }}</span>
        </template>

        <!------------------- Date related ------------------->
        <template v-slot:[`item.dates`]="{ item, index }">
          <!-- DD/MM(W) -->
          <span v-for="(date, i) in item.dates" :key="`course-date-${index}_${date}`">
            {{ date.substring(8, 10) }}/{{ date.substring(5, 7) }} ({{ $d(new Date(date), 'weekdayShort') }}){{ i !== item.dates.length - 1 ? ',' : '' }}
          </span>
        </template>

        <template v-slot:[`item.full_date`]="{ item, header }">
          <!-- DD/MM/YYYY -->
          <span v-if="$validate.DataValid(item.date)">
            {{ item.date.substring(8, 10) }}/{{ item.date.substring(5, 7) }}/{{ item.date.substring(0, 4) }}
            {{ header.hasWeekday ? `(${ $d(new Date(item.date), 'weekdayShort') })` : '' }}
          </span>
        </template>

        <template v-slot:[`item.date_month`]="{ item }">
          <!-- DD-MM -->
          <span v-if="$validate.DataValid(item.date)">{{ item.date.substring(8, 10) }}-{{ item.date.substring(5, 7) }}</span>
        </template>

        <template v-slot:[`item.weekday`]="{ item }">
          <!-- W -->
          <span v-if="$validate.DataValid(item.date)">{{ $d(new Date(item.date), 'weekdayShort') }}</span>
        </template>

        <template v-slot:[`item.month`]="{ item, header }">
          <!-- MM -->
          <span v-if="$validate.DataValid(item.month)">{{ header.hasYear && item.year ? `${item.year}/` : '' }}{{ $formatter.pad(item.month) }}</span>
        </template>

        <!------------------- Common ------------------->
        <template v-slot:[`item.id`]="{ item }">
          <span>{{ $formatter.generateId(item.id) }}</span>
        </template>

        <template v-slot:[`item.grade`]="{ item }">
          <span>{{ item.grade | formatGrade }}</span>
        </template>

        <template v-slot:[`item.timeslot`]="{ item }">
          <span v-if="$validate.DataValid(item.start_time) && $validate.DataValid(item.end_time)">{{ item.start_time }} - {{ item.end_time }}</span>
        </template>

        <template v-slot:[`item.unit_price`]="{ item }">
          <div :class="{ 'text-decoration-line-through': $validate.DataValid(item.discount_price) }">
            HK${{ $formatter.separator(item.unit_price) }}
          </div>
          <div v-if="$validate.DataValid(item.discount_price)">HK${{ $formatter.separator(item.discount_price) }}</div>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <span v-if="$validate.DataValid(item.total)">HK${{ $formatter.separator(item.total) }}</span>
        </template>

        <template v-slot:[`item.minActionEditAndDelete`]="{ item }">
          <div class="d-flex align-center justify-end">
            <v-btn
              text
              icon
              min-width="max-content"
              class="pa-0 mx-2"
              :ripple="false"
              @click="clickActionEdit(item.id)"
              :class="{ 'pointer-none': $store.getters.isLoading }"
            >
              <v-icon class="fi fi-ss-pen-circle" color="accent" size="24"></v-icon>
            </v-btn>
            <v-btn
              text
              icon
              min-width="max-content"
              class="pa-0 mx-2"
              :ripple="false"
              @click="clickActionDelete(item.id)"
              :class="{ 'pointer-none': $store.getters.isLoading }"
            >
              <v-icon class="fi fi-ss-cross-circle" color="red" size="24"></v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:[`item.actionView`]="{ item }">
          <v-btn
            depressed
            text
            color="primary"
            :ripple="false"
            class="pa-0"
            :to="{ name: actionViewRouteLink, params: { id: item.id } }"
          >
            <v-icon class="fi fi-rr-eye mr-1" size="18"></v-icon>
            {{ $t('table.view') }}
          </v-btn>
        </template>

        <template v-slot:[`item.actionDelete`]="{ item }">
          <v-btn depressed text color="error" :ripple="false" class="pa-0" @click="clickActionDelete(item.id)">
            <v-icon class="fi fi-rr-trash mr-1" size="18"></v-icon>
            {{ $t('delete') }}
          </v-btn>
        </template>

        <template v-slot:[`item.actionDownloadDoc`]="{ item, header }">
          <v-btn
            :loading="downloadLoading"
            v-if="$validate.DataValid(item.downloadDoc)"
            class="primary"
            depressed
            text
            download
            >{{ $t(header.btnTitle || 'table.download') }}</v-btn
          >
        </template>

        <template v-slot:[`item.actionExportDoc`]="{ item, header }">
          <v-btn :loading="downloadLoading" class="primary" depressed text @click="clickActionExportDoc(item.id)" :class="{'unflex': $i18n.locale === 'en'}">
            {{ $t(header.btnTitle || 'table.download') }}
          </v-btn>
        </template>
        <slot></slot>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify, mdiCheck, mdiClose, mdiRefresh, mdiFileTableOutline, mdiTableArrowLeft } from '@mdi/js'

import QtySetter from '@/components/QtySetter.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormInput from '@/components/formField/FormInput.vue'
import Searchbar from '@/components/Searchbar.vue'

export default {
  // ------ page properties ------
  name: 'Datatable',
  components: {
    QtySetter,
    FormDatePicker,
    FormInput,
    Searchbar,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    customHeader: {
      type: Boolean,
      default: false,
    },
    datePlaceholder: {
      type: String,
      default: 'table.searchDate',
    },
    dateRangePlaceholder: {
      type: String,
      default: 'table.searchDateRange',
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    disableFirstLastPage: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    downloadLoading: {
      type: Boolean,
      default: false,
    },
    enableSort: {
      type: Boolean,
      default: false,
    },
    enableAdvancedSearch: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    enableDateRange: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    enableFiltering: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableRefresh: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableMultiTypeSearch: {
      type: Boolean,
      default: false,
    },
    enableSearchDate: {
      type: Boolean,
      default: false,
    },
    enableSelectToggle: {
      type: Boolean,
      default: false,
    },
    enableSelectAction: {
      type: Boolean,
      default: false,
    },
    initSearchKeyword: {
      type: String,
      default: '',
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    pageLimitOptions: {
      type: Array,
      default: () => [10, 20, 50],
    },
    searchPlaceholder: {
      type: String,
      default: 'search',
    },
    searchType: {
      type: String,
      default: '',
    },
    searchTypeOptions: {
      type: Array,
      default: () => [],
    },
    selectActionList: {
      type: Array,
      default: () => [], // 'edit' | 'delete'
    },
    selectedTableRow: {
      type: Array,
      default: () => [],
    },
    tableEmptyText: {
      type: String,
      default: 'table.noData',
    },
    tableName: {
      type: String,
      default: '',
    },
    tableSmallText: {
      type: String,
      default: '',
    },
    noWrapToolbar: {
      type: Boolean,
      default: false,
    },
    disabledSelectAll: {
      type: Boolean,
      default: false,
    },
    enableImport: {
      type: Boolean,
      default: false,
    }
  },

  // ------ page variables ------
  data: () => ({
    icon: {
      mdiMagnify,
      mdiCheck,
      mdiClose,
      mdiRefresh,
      mdiFileTableOutline,
      mdiTableArrowLeft
    },

    options: {},
    searchDate: '',
    searchDateRange: [],
    searchText: '',
    tableInit: false,
    selectedRow: [],
    searchKeyInit: true,
    selectAction: '',
  }),

  // ------ mutation observer ------
  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
    selectedTableRow: {
      handler(val) {
        this.selectedRow = val
      },
      deep: true,
      immediate: true,
    },
    pageLimit: {
      handler(val) {
        if (val !== this.options.itemsPerPage) {
          this.options.itemsPerPage = val
        }
      },
    },
    initSearchKeyword: {
      handler(val) {
        if (this.searchKeyInit) {
          this.searchText = val
        }

        this.searchKeyInit = false
      },
      immediate: true,
    },
  },

  // ------ computed values ------
  computed: {
    locale() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'en'
        case 'cn':
          return 'zh-CN'
        default:
          return 'zh-HK'
      }
    },
    processing() {
      return this.isLoading
    },
    hideDefaultHeader() {
      if (this.customHeader) {
        if (!this.breakpoint) {
          return true
        }

        if (this.$vuetify.breakpoint.width >= this.breakpoint) {
          return true
        }
      }

      return false
    },
  },

  // ------ page functions ------
  methods: {
    clickAdvancedSearch() {
      if (this.disablePagination) {
        this.$emit('advanced-search-clicked', false)
      } else {
        this.$emit('advanced-search-clicked', true)
      }
    },
    clickClearAll() {
      this.searchText = ''
      this.searchDate = ''
      this.searchDateRange = []
      this.$emit('all-search-cleared')
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickExport() {
      this.$emit('export-clicked')
    },
    clickActionEdit(id) {
      this.$emit('edit-clicked', id)
    },
    clickActionDelete(id) {
      this.$emit('delete-clicked', id)
    },
    clickActionApply(id) {
      this.$emit('apply-clicked', id)
    },
    updateOptions() {
      if (this.tableInit) {
        this.$emit('options-update', this.options)
      }
      this.tableInit = true
    },
    updateSelectedRow(rows) {
      this.$emit('update:selectedTableRow', rows)
      this.$emit('selected-row-changed', rows)
    },
    selectDate() {
      this.$emit('date-selected', this.searchDate)
    },
    selectDateRange() {
      this.$emit('daterange-selected', dateRange)
    },
    onCustomSelectClick(ev, id) {
      if (this.$store.getters.isLoading) {
        ev.preventDefault()
        return
      }

      this.$emit('checkbox-click', id)
    },
    selectActionChanged(val) {
      this.$emit('select-action-changes', val)
      this.$nextTick(() => {
        this.selectAction = ''
      })
    },
    clickActionExportDoc(id) {
      this.$emit('export-doc-clicked', id)
    },
    _triggerUploadFile() {
      document.getElementById('table-import-file').click()
    },
    async clickImport(file) {
      this.$emit('import-clicked', file);
    },
  },

  // ------ life cycle events ------
  created() {},
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table.custom-table .v-data-table-header-mobile .v-text-field .v-select__selections {
  margin-top: 0.5rem;
}
::v-deep .custom-table-toolbar .v-input--selection-controls .v-input__slot > .v-label {
  font-size: 0.875rem;
}

::v-deep .custom-table-toolbar .v-input input,
::v-deep .custom-table-toolbar .v-input label {
  font-size: 0.825rem;
}

::v-deep .v-data-table.custom-table > .v-data-table__wrapper > table > tbody > tr > th,
::v-deep .v-data-table.custom-table > .v-data-table__wrapper > table > thead > tr > th,
::v-deep .v-data-table.custom-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 0.75rem;
  font-weight: 500;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
::v-deep .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--v-colorBlack-base);
  font-size: 0.875rem;

  &.v-data-table__mobile-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & .v-btn.v-size--default {
    font-size: 0.875rem;
  }
}

::v-deep .v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  padding-top: 1rem;
}
::v-deep .v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-row:last-of-type {
  padding-bottom: 1rem;
}
::v-deep .v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 2.2rem;
}

::v-deep .v-data-table.custom-table .v-data-table__empty-wrapper {
  & > td {
    height: 175px !important;
    color: var(--v-colorBlack-base) !important;
  }
}

::v-deep .v-data-table.custom-table.v-data-table--mobile tbody .v-data-table__empty-wrapper {
  display: block;
  & > td {
    width: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.hide-page-select .v-data-footer__select {
  visibility: none;
  opacity: 0;
  pointer-events: none;
}

.v-dialog.table-dialog {
  margin: 0 auto;
}

.red-text {
  color: var(--v-error-base);
}

::v-deep .v-data-table-header .v-simple-checkbox {
  line-height: 1 !important;
  & .v-icon {
    color: var(--v-colorWhite-base) !important;
  }
}

::v-deep .v-data-table-header-mobile__select {
  min-width: unset !important;
  & .v-simple-checkbox {
    line-height: 1 !important;
    & .v-icon {
      color: var(--v-primary-base) !important;
    }
  }
}

::v-deep.custom-table.show-select {
  & .v-data-table-header-mobile > tr > th {
    height: 0;
  }
  & .v-data-table-header-mobile__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 16px 0;
  }
}

::v-deep tbody .v-simple-checkbox {
  & .v-icon {
    color: var(--v-primary-base) !important;
  }
}

::v-deep .v-data-table-header th > span,
::v-deep .v-data-table__mobile-row__header {
  white-space: nowrap !important;
}

::v-deep .action-selector {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  margin-bottom: 0 !important;

  & .v-icon {
    color: #ffffff;
  }

  & .v-input__control {
    min-height: 24px !important;
    border: 1px solid #ffffff;
  }

  & .v-input__slot {
    padding: 0 5px !important;
  }

  & .v-label,
  & .v-select__selection {
    font-size: 12px;
    color: #ffffff !important;
  }

  & .v-input__append-inner {
    margin-top: 0 !important;
  }
}

::v-deep .search-input-selector {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  margin-bottom: 0 !important;

  & input {
    display: none !important;
  }

  & .v-input__icon {
    color: #d9d9d9;
    height: 10px !important;
    width: 10px !important;
    min-width: 10px !important;
  }

  & .v-input__control {
    min-height: 24px !important;
    // border: 1px solid #d9d9d9;
    border: none;
  }

  & .v-input__slot {
    padding: 0 5px !important;
  }

  & .v-label,
  & .v-select__selection {
    font-size: 12px;
    color: var(--v-colorGrey-base) !important;
    margin-right: 0 !important;
    width: 100%;
    // text-align: center;
  }

  & .v-input__append-inner {
    // display: none !important;
    margin-top: 0 !important;
  }
}

::v-deep .search-input-field .v-input__prepend-inner {
  margin-top: 6px !important;
}

::v-deep .v-data-table-header-mobile__select .action-selector {
  & .v-icon,
  & .v-label,
  & .v-select__selection {
    color: var(--v-primary-base) !important;
  }

  & .v-input__control {
    border-color: var(--v-primary-base) !important;
  }
}

::v-deep .v-data-table-header .action-selector {
  &.v-input {
    &.v-input--is-disabled {
      & .v-input__slot {
        background: transparent !important;
      }

      & .v-input__slot fieldset {
        border-color: var(--v-primary-base) !important;
      }
    }
  }
}

::v-deep.v-data-table.custom-table {
  & .v-radio > .v-label {
    font-size: 0.75rem !important;
  }
}
</style>
